import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "@datwyler/mfe-shared-components";
import Tenant from "@containers/tenant";

const Router = () => {
  return (
    <Routes>
      <Route path="/admin" element={<Tenant />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
