import { useState, useEffect } from "react";
import { useTenant } from "@datwyler/mfe-shared-components";

const useAdmin = (tenantId) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const { updateSupportEmails, updateSupportEmailsResponse } = useTenant();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId]);

  const updateSupportEmailsList = (supportMailAddresses) => {
    updateSupportEmails({
      variables: {
        input: {
          tenant: { id: tenantID },
          supportEmails: supportMailAddresses || [],
        },
      },
    });
  };

  return {
    updateSupportEmailsList,
    updateSupportEmailsListResult: updateSupportEmailsResponse,
  };
};

export default useAdmin;
