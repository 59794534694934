import { useState, useContext } from "react";
import { Space } from "antd";
import {
  BasicModal,
  Dragger,
  commonStyles,
  NotificationContext,
  ApiContext,
  TenantIdContext,
} from "@datwyler/mfe-shared-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UploadLicenseType,
  NotificationContextType,
  TenantIdContextType,
  ApiContextType,
} from "@types";
const { Button16Primary, Button16Invert } = commonStyles;
const allowedExtensions = [];

const UploadCertificate = ({ open, setOpen, intl }: UploadLicenseType) => {
  const [filelist, setFilelist] = useState([]);
  const { openNotification } =
    useContext<NotificationContextType>(NotificationContext);
  const { uri, apiInstance } = useContext<ApiContextType>(ApiContext);
  const { tenantId, refetchTenantById } =
    useContext<TenantIdContextType>(TenantIdContext);
  const location = useLocation();
  const navigate = useNavigate();

  const handleOnOk = () => {
    if (filelist.length < 1) {
      openNotification({
        type: "warning",
        description: intl.formatMessage({
          id: "file_required",
        }),
        placement: "topRight",
      });

      return;
    }

    const formData = new FormData();
    formData.append("file", filelist[0]);

    const url = uri + "/api/v1/tenants/" + tenantId + "/certificate";
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    apiInstance
      .post(url, formData, config)
      .then((response) => {
        if (response?.status === 200) {
          openNotification({
            type: "success",
            description: intl.formatMessage({
              id: "upload_success",
            }),
            placement: "topRight",
          });
          setFilelist([]);
          setOpen(false);
          refetchTenantById();

          const newTenantId = response?.data?.id;
          if (newTenantId) {
            const state = { ...location.state, tenantId: response.data?.tenant?.id };
            navigate(location.pathname, { state: state });
          }
        }
      })
      .catch((error) => {
        openNotification({
          type: "success",
          description: error.message,
          placement: "topRight",
        });
      });
  };

  return (
    <BasicModal
      isOpen={open}
      title={intl.formatMessage({ id: "tenant_upload_certificate" })}
      handleCancel={() => setOpen(false)}
      footer={() => {
        return (
          <Space>
            <Button16Primary onClick={() => setOpen(false)}>
              {intl.formatMessage({ id: "cancel" })}
            </Button16Primary>
            <Button16Invert type="primary" onClick={() => handleOnOk()}>
              {intl.formatMessage({ id: "tenant_upload_certificate" })}
            </Button16Invert>
          </Space>
        );
      }}
    >
      <Dragger
        allowedExtensions={allowedExtensions}
        filelist={filelist}
        setFilelist={setFilelist}
        fileSizeUnit={1024}
      />
    </BasicModal>
  );
};

export default UploadCertificate;
