import React from "react";
import { Card, Flex, Divider, Row, Col } from "antd";
import { commonStyles } from "@datwyler/mfe-shared-components";

const { Title20Primary, Body16RegularPrimary, Body16RegularTertiary } =
  commonStyles;

const Information = ({
  actions,
  items = [],
  title = "",
}: {
  actions: React.ReactNode;
  items: {
    label: string;
    value: string;
    labelSpan: number;
    valueSpan: number;
  }[];

  title: string;
}) => {
  return (
    <Card>
      <Flex justify="space-between">
        <Title20Primary>{title}</Title20Primary>
        <Flex style={{gap: "24px"}}>{actions}</Flex>
      </Flex>
      {items.length > 0 && <Divider />}
      <Row gutter={[16, 16]}>
        {items.map(({ label, value, labelSpan, valueSpan }, index) => (
          <React.Fragment key={index}>
            <Col sm={labelSpan} xs={12}>
              <Body16RegularTertiary>{label}</Body16RegularTertiary>
            </Col>
            <Col sm={valueSpan} xs={12}>
              <Body16RegularPrimary style={{ overflowWrap: "anywhere" }}>
                {value}
              </Body16RegularPrimary>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </Card>
  );
};

export default Information;
