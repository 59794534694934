import { useState, useContext, useEffect } from "react";
import { Flex } from "antd";
import {
  TenantIdContext,
  NotificationContext,
  UserContext,
  withPermission,
  commonStyles,
  useIntl,
  MailIcon,
  UploadIcon,
  lodash as _,
  moment,
  getRequiredDateFormat
} from "@datwyler/mfe-shared-components";
import useAdmin from "@hooks/useAdmin";
import Information from "@components/Information";
import Breadcrumbs from "@components/Breadcrumb";
import UploadLicense from "@components/UploadLicense";
import { NotificationContextType, TenantIdContextType } from "@types";
import SupportEmail from "@components/SupportEmail";
import { isUserAdmin, isRegionTenant } from "@helpers";
import UploadCertificate from "@components/UploadCertificate";

const { Title, Button16Primary } = commonStyles;

const Tenant = () => {
  const intl = useIntl();
  const [showSupportEmails, setShowSupportEmails] = useState(false);
  const [updatedEmailList, setupdatedEmailList] = useState<string[]>();
  const [showUploadLicense, setShowUploadLicense] = useState(false);
  const [showUploadCertificate, setShowUploadCertificate] = useState(false);
  const { openNotification } =
    useContext<NotificationContextType>(NotificationContext);
  const { user } = useContext<{ user: { role: string } }>(UserContext);
  const { tenantId, tenantInfo: tenant } =
    useContext<TenantIdContextType>(TenantIdContext);

  const { updateSupportEmailsList, updateSupportEmailsListResult } =
    useAdmin(tenantId);

  useEffect(() => {
    if (updateSupportEmailsListResult) {
      openNotification({
        type: "success",
        description: intl.formatMessage({
          id: "tenant_update_emails_success",
        }),
        placement: "topRight",
      });
      const supportEmails = _.get(
        updateSupportEmailsListResult,
        "updateSupportEmail.supportEmails.emails",
        []
      );
      setupdatedEmailList([...supportEmails]);
    }
  }, [openNotification, intl, updateSupportEmailsListResult]);

  const emailList = updatedEmailList
    ? updatedEmailList
    : _.get(tenant, "supportEmails", []);

  const license = _.get(tenant, "license", null);
  const tenantactions = (
    <>
      {isRegionTenant(tenant) && isUserAdmin(user) && (
        <>
          <Button16Primary
            onClick={() => setShowUploadCertificate(true)}
            icon={<UploadIcon width={24} height={24} />}
          >
            {intl.formatMessage({ id: "tenant_upload_certificate" })}
          </Button16Primary>
          <Button16Primary
            onClick={() => setShowSupportEmails(true)}
            icon={<MailIcon width={24} height={24} />}
          >
            {intl.formatMessage({ id: "tenant_manage_email" })}
          </Button16Primary>
        </>
      )}
    </>
  );

  const licenseActions = (
    <>
      {isUserAdmin(user) && (
        <Button16Primary
          onClick={() => setShowUploadLicense(true)}
          icon={<UploadIcon width={24} height={24} />}
          disabled={isRegionTenant(tenant) && !tenant?.certificate}
        >
          {intl.formatMessage({ id: "tenant_upload_license" })}
        </Button16Primary>
      )}
    </>
  );

  const tenantInformation = [
    {
      label: intl.formatMessage({ id: "tenant_id" }),
      value: _.get(tenant, "id", ""),
      labelSpan: 4,
      valueSpan: 20,
    },
    {
      label: intl.formatMessage({ id: "tenant_name" }),
      value: _.get(tenant, "name", ""),
      labelSpan: 4,
      valueSpan: 20,
    },
    {
      label: intl.formatMessage({ id: "support_email_list" }),
      value: (emailList || []).join(", "),
      labelSpan: 4,
      valueSpan: 20,
    },
  ];

  let certificateInformation = [];
  if(tenant?.certificate) certificateInformation = [
    {
      label: intl.formatMessage({ id: "id" }),
      value: _.get(tenant, "certificate.id", ""),
      labelSpan: 4,
      valueSpan: 20,
    },
    {
      label: intl.formatMessage({ id: "checksum" }),
      value: _.get(tenant, "certificate.checksum", ""),
      labelSpan: 4,
      valueSpan: 20,
    },
    {
      label: intl.formatMessage({ id: "created_by" }),
      value: `${_.get(tenant, "certificate.createdBy.firstName", "")} ${_.get(tenant, "certificate.createdBy.lastName", "")} `,
      labelSpan: 4,
      valueSpan: 20,
    },
    {
      label: intl.formatMessage({ id: "created_on" }),
      value: getRequiredDateFormat(_.get(tenant, "certificate.createdOn", ""), "YYYY-MM-DD hh:mm"),
      labelSpan: 4,
      valueSpan: 20,
    },
  ];

  const licenseInformation = [
    {
      label: intl.formatMessage({ id: "license_number" }),
      value: _.get(license, "id", ""),
      labelSpan: 4,
      valueSpan: 20,
    },

    {
      label: intl.formatMessage({ id: "license_start_date" }),
      value: moment
        .unix(_.get(license, "startDate", ""))
        .format("DD-MM-YYYY HH:mm"),
      labelSpan: 4,
      valueSpan: 8,
    },
    {
      label: intl.formatMessage({ id: "license_end_date" }),
      value: moment
        .unix(_.get(license, "endDate", ""))
        .format("DD-MM-YYYY HH:mm"),
      labelSpan: 4,
      valueSpan: 8,
    },
    {
      label: intl.formatMessage({ id: "license_number_users" }),
      value: _.get(license, "users.total", ""),
      labelSpan: 4,
      valueSpan: 8,
    },
    {
      label: intl.formatMessage({ id: "current_user_usage" }),
      value: _.get(license, "users.current", ""),
      labelSpan: 4,
      valueSpan: 8,
    },

    {
      label: intl.formatMessage({ id: "license_cabinets" }),
      value: _.get(license, "cabinets", ""),
      labelSpan: 4,
      valueSpan: 8,
    },
    {
      label: intl.formatMessage({ id: "license_dcim_controllers" }),
      value: _.get(license, "controllers", ""),
      labelSpan: 4,
      valueSpan: 8,
    },
  ];

  const handleSupportEmails = (emails) => {
    updateSupportEmailsList(emails);
    setShowSupportEmails(false);
  };

  return (
    <>
      <Flex vertical gap={24}>
        <Breadcrumbs items={[{ title: "tenant_admin" }]} />
        <Title>{intl.formatMessage({ id: "tenant_admin" })}</Title>
        <Information
          actions={tenantactions}
          items={tenantInformation}
          title={intl.formatMessage({ id: "tenant_info" })}
        />
        {tenant?.certificate && isRegionTenant(tenant) && isUserAdmin(user) && (
          <Information
            actions={null}
            items={tenant?.certificate ? certificateInformation : []}
            title={intl.formatMessage({ id: "certificate_metadata" })}
          />
        )}
        <Information
          actions={licenseActions}
          items={license ? licenseInformation : []}
          title={intl.formatMessage({ id: "tenant_license_info" })}
        />
      </Flex>
      <SupportEmail
        title={intl.formatMessage({ id: "tenant_manage_email" })}
        open={showSupportEmails}
        handleOk={handleSupportEmails}
        handleCancel={() => setShowSupportEmails(false)}
        intl={intl}
        emailList={emailList}
        setEmailList={setupdatedEmailList}
      />
      <UploadLicense
        open={showUploadLicense}
        setOpen={setShowUploadLicense}
        intl={intl}
      />
      <UploadCertificate
        open={showUploadCertificate}
        setOpen={setShowUploadCertificate}
        intl={intl}
      />
    </>
  );
};

export default withPermission("/admin")(Tenant);
