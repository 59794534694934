import { Form, Flex, Tag, Space } from "antd";
import {
  BasicModal,
  commonStyles,
  useTheme,
} from "@datwyler/mfe-shared-components";
import { SupportEmailType } from "@types";
import { useEffect, useState } from "react";

const { Body14RegularPrimary, Button16Primary, Button16Invert, InputField } =
  commonStyles;

const SupportEmail = ({
  title,
  open,
  handleOk,
  handleCancel,
  intl,
  emailList,
}: SupportEmailType) => {
  const theme = useTheme();
  const [form] = Form.useForm();
  const { getFieldError, getFieldValue, resetFields } = form;
  const [localEmailList, setLocalEmailList] = useState([]);

  useEffect(() => {
    setLocalEmailList([...emailList]);
  }, [emailList]);

  const handleRemoveTag = (removedEmail) => {
    const updatedList = localEmailList.filter(
      (email) => email !== removedEmail
    );
    setLocalEmailList(updatedList);
  };

  const handleAddTag = () => {
    if (getFieldError("email").length <= 0) {
      const value = getFieldValue("email");
      setLocalEmailList([...localEmailList, value]);
      resetFields();
    }
  };

  const onCancelClick = () => {
    handleCancel();
    resetFields();
    setLocalEmailList([...emailList]);
  };

  return (
    <BasicModal
      title={title}
      isOpen={open}
      afterClose={resetFields}
      footer={() => {
        return (
          <Space>
            <Button16Primary onClick={onCancelClick}>
              {intl.formatMessage({ id: "cancel" })}
            </Button16Primary>
            <Button16Invert type="primary" htmlType="submit">
              {intl.formatMessage({ id: "save_changes" })}
            </Button16Invert>
          </Space>
        );
      }}
      handleCancel={onCancelClick}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          onFinish={() => handleOk(localEmailList)}
          form={form}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="email"
        label={
          <Body14RegularPrimary>
            {intl.formatMessage({ id: "tenant_enter_email" })}
          </Body14RegularPrimary>
        }
        rules={[
          {
            required: false,
            type: "email",
            message: intl.formatMessage({ id: "tenant_email_valid" }),
          },
          {
            message: intl.formatMessage({ id: "tenant_email_exist" }),
            validator: (_, value) => {
              const isExist = localEmailList.find((email) => email === value);
              if (isExist)
                return Promise.reject(
                  intl.formatMessage({ id: "tenant_email_exist" })
                );
              return Promise.resolve();
            },
          },
        ]}
      >
        <InputField
          placeholder={intl.formatMessage({ id: "tenant_email_enter" })}
          variant="filled"
          onPressEnter={handleAddTag}
        />
      </Form.Item>
      <Flex gap="8px" wrap style={{ paddingBottom: 16 }}>
        {localEmailList.map((email) => (
          <Tag
            key={email}
            closable
            bordered={false}
            style={{
              padding: 8,
              background: theme.datwylerSurface.surfaceScreen,
            }}
            onClose={() => handleRemoveTag(email)}
          >
            <Body14RegularPrimary>{email}</Body14RegularPrimary>
          </Tag>
        ))}
      </Flex>
    </BasicModal>
  );
};

export default SupportEmail;
