import { useContext } from "react";
import { ConfigProvider } from "antd";
import { useLocation } from "react-router-dom";
import {
  lodash as _,
  DarkModeContext,
  TenantIdProvider,
  UserProvider,
} from "@datwyler/mfe-shared-components";
import Router from "./routes";
import { ThemeContextType } from "@types";
const App = () => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();
  const tenantId = _.get(location, "state.tenantId");
  return (
    <>
      <UserProvider>
        <TenantIdProvider tenantId={tenantId}>
          <ConfigProvider theme={theme}>
            <Router />
          </ConfigProvider>
        </TenantIdProvider>
      </UserProvider>
    </>
  );
};

export default App;
