import { lodash as _ } from "@datwyler/mfe-shared-components";
const ADMIN_ROLE = "ADMIN";

export const isUserAdmin = (user) => {
  return _.get(user, "role", "") === ADMIN_ROLE;
};

export const isRegionTenant = (tenant) => {
  return _.get(tenant, "level", -1) === 0;
};
